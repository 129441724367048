import React from 'react';
import { withStory, WithStoryProps } from '@storyofams/storyblok-toolkit';
import { GetStaticProps, NextPage } from 'next';

import { Seo } from '~components';
import { globalStaticProps, withLayout } from '~lib';
import { DynamicSections } from '~components/dynamic';
import { HeroSection } from '~components/dynamic/sections';
import storyblokSDK from '~lib/storyblok/client';

interface HomePageProps extends WithStoryProps {}

const Home: NextPage<HomePageProps> = ({ story, ...props }) => {
  return (
    <>
      <Seo story={story} />
      <HeroSection content={story?.content?.hero?.[0]} />

      <DynamicSections content={{ sections: story?.content?.body }} />
    </>
  );
};

export default withLayout(withStory(Home));

export const getStaticProps: GetStaticProps<HomePageProps> =
  globalStaticProps(async ({ defaultLocale, locale }) => {
    let story = null;
    let notFound = false;
    const [lang, ] = locale.split('-');

    try {
      story = (
        await storyblokSDK(2).home({
          slug: `${lang}/home`,
        })
      );
    } catch (e) {
      console.log(e, 'error');
      notFound = true;
    }

    return {
      props: {
        story: story.HomepageItem,
      },
      notFound: notFound || !story,
      revalidate: 60,
    };
  });
